<template>
  <div class="container bg-dark">
    <h1 class="text-white">
      {{ $t('startScreen.startVerification') }}
    </h1>
    <div class="steps">
      <div class="step text-white">
        <i class="icon icon-profile" /> {{ $t('startScreen.prepareValid') }} {{ generateDocumentName() }}
      </div>
      <div class="step text-white">
        <i class="icon icon-camera" /> {{ $t('startScreen.choosePlace') }}
      </div>
      <div class="step text-white">
        <i class="icon icon-scan" /> {{ $t('startScreen.prepareToTakePhoto') }}
      </div>
    </div>
    <div class="end-content">
      <p class="text-white text-description">
        {{ $t('startScreen.description') }}
        <br>
        <br>
        <router-link
          :to="{name: 'privacyPolicy' }"
          class="text-white"
          target="_blank"
        >
          {{ $t('startScreen.privacyPolicy') }}
        </router-link>
      </p>

      <a
        class="btn btn-white"
        href="javascript:"
        @click="$emit('next-step', { step: generateNextStep() })"
      >
        {{ $t('startScreen.start') }}
      </a>
    </div>
  </div>
</template>
<script type="text/javascript">
import { FLOW_TYPES, USER_STEPS } from '../../utils/constants';

export default {
  name: 'StartScreen',
  props: {
    flow: {
      type: String,
      default() {
        return '';
      },
    },
  },
  emits: ['next-step'],
  methods: {
    generateDocumentName() {
      return this.flow === FLOW_TYPES.ID_CARD ? this.$t('startScreen.idCard') : this.$t('startScreen.passport');
    },
    generateNextStep() {
      return this.flow === FLOW_TYPES.ID_CARD ? USER_STEPS.ID_UPLOAD : USER_STEPS.PASSPORT_UPLOAD;
    },
  },
};
</script>
