import { USER_STEPS } from '../../utils/constants';

export default {
  data() {
    return {
      cameraImage: null,
      camera: null,
      cameraError: null,
      cameraReady: false,
      cameraOpened: false,
      deviceId: null,
      devices: [],
    };
  },
  watch: {
    camera(id) {
      this.deviceId = id;
    },
    cameraEnabled(val) {
      this.enableCamera(val);
    },
  },
  methods: {
    enableCamera(val) {
      const ref = this.cameraRef();
      if (ref) {
        if (val) {
          ref.start();
        } else {
          ref.stop();
        }
      }
    },
    cameraRef() {
      return this.$refs.camdesktop;
    },
    windowWidth() {
      return window.innerWidth;
    },
    windowHeight() {
      return window.innerHeight;
    },
    onCameraError() {
      this.$emit('next-step', { step: USER_STEPS.CAMERA_ACCESS });
    },
    onNotSupportedError() {
      this.$emit('next-step', { step: USER_STEPS.ERROR });
    },
    onStarted() {
      this.cameraReady = true;
    },
    onStopped() {
      this.cameraReady = false;
    },
    onCapture() {
      const ref = this.cameraRef();
      if (ref) {
        this.cameraImage = ref.capture();
      }
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n > 0) {
        n -= 1;
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    switchToNextCamera() {
      let currentCameraIndex = this.devices.findIndex(obj => obj.deviceId === this.deviceId);
      if (currentCameraIndex === -1) {
        currentCameraIndex = 0;
      }
      if (this.devices[currentCameraIndex + 1]) {
        this.onCameraChange(this.devices[currentCameraIndex + 1].deviceId);
      } else if (currentCameraIndex !== 0) {
        this.onCameraChange(this.devices[0].deviceId);
      }
    },
  },
};
