<template>
  <div class="container bg-white">
    <h2 class="text-error">
      {{ $t('notSupported.title') }}
    </h2>
    <p class="text-description align-center">
      {{ $t('notSupported.description') }} 😿
    </p>
    <p class="text-description align-center">
      {{ $t('notSupported.description2') }}
    </p>
    <div class="end-content">
      <p class="text-gray align-center text-description">
        {{ $t('notSupported.tryAgainDescription') }}
      </p>
      <a
        class="btn btn-dark"
        href="javascript:"
        @click="$emit('next-step', { step: 'START_SCREEN' })"
      >
        {{ $t('cameraAccess.tryAgain') }}
      </a>
    </div>
  </div>
</template>
