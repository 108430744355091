import { USER_STEPS } from './constants';
import StartScreen from '../components/screens/StartScreen.vue';
import IdUpload from '../components/screens/IdUpload.vue';
import IdUploadBack from '../components/screens/IdUploadBack.vue';
import PassportUpload from '../components/screens/PassportUpload.vue';
import Selfie from '../components/screens/Selfie.vue';
import CameraAccess from '../components/screens/errors/CameraAccess.vue';
import NotSupported from '../components/screens/errors/NotSupported.vue';
import IdFailed from '../components/screens/errors/IdFailed.vue';
import SelfieFailed from '../components/screens/errors/SelfieFailed.vue';
import PassportFailed from '../components/screens/errors/PassportFailed.vue';
import VerificationSuccess from '../components/screens/VerificationSuccess.vue';
import LoadingScreen from '../components/screens/LoadingScreen.vue';

const determineCurrentComponent = (step) => {
  switch (step) {
    case USER_STEPS.START_SCREEN:
      return StartScreen;
    case USER_STEPS.ID_UPLOAD:
      return IdUpload;
    case USER_STEPS.ID_UPLOAD_BACK:
      return IdUploadBack;
    case USER_STEPS.PASSPORT_UPLOAD:
      return PassportUpload;
    case USER_STEPS.SELFIE_UPLOAD:
      return Selfie;
    case USER_STEPS.CAMERA_ACCESS:
      return CameraAccess;
    case USER_STEPS.ERROR:
      return NotSupported;
    case USER_STEPS.VERIFICATION_SUCCESS:
      return VerificationSuccess;
    case USER_STEPS.ID_FAILED:
      return IdFailed;
    case USER_STEPS.SELFIE_FAILED:
      return SelfieFailed;
    case USER_STEPS.PASSPORT_FAILED:
      return PassportFailed;
    case USER_STEPS.LOADING:
      return LoadingScreen;
    default:
      return LoadingScreen;
  }
};

export default determineCurrentComponent;
