<template>
  <div class="main-container ev-step-box">
    <header class="header">
      <div class="container is-fluid line has-text-centered pt-4 pb-4 has-h-4">
        <a
          href="/"
          style="text-align: center"
        >
          <img
            src="@/assets/img/facem_logo.png"
            alt="facem"
          >
        </a>
      </div>
    </header>

    <div class="box box-view-page is-shadowless">
      <main>
        <div
          class="is-h100 is-vcentered direction-column home-box"
          style="margin: 0 auto"
        >
          <div class="field has-addons w-100">
            <div class="control w-100">
              <input
                v-model="code"
                style="display: block; margin: 0 auto 20px; padding: 10px 5px"
                class="input"
                type="text"
                placeholder="Input verification code"
              >
            </div>
            <div class="control">
              <button
                style="display: block; margin: 0 auto"
                class="button is-primary"
                @click="checkVerificationCode"
              >
                Submit
              </button>
            </div>
          </div>
          <p
            v-if="error"
            style="text-align: center"
          >
            {{ error }}
          </p>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

import API from '../api/api';

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      code: '',
      error: '',
    };
  },
  methods: {
    checkVerificationCode() {
      API.Verification.fetchByCode(this.code).then(
        (data) => {
          if (data) {
            if (data.locale) {
              this.$root.$i18n.locale = data.locale;
            }
            if (data.state !== 'pending') {
              this.error = 'Code expired or invalid';
            } else {
              this.$router.push(
                { name: 'verification', params: { code: this.code } },
              );
            }
          } else {
            this.error = 'Code expired or invalid';
          }
        },
        () => {
          this.error = 'Unknown error';
        },
      );
    },
  },
};
</script>
<style>
    * {
     -webkit-text-size-adjust: none;
      text-size-adjust: none;
    }
</style>
