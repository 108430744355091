export default {
  data() {
    return {
      imageSrc: null,
    };
  },
  props: {
    selectedImage: {
      type: File,
      default() {
        return null;
      },
    },
  },
  mounted() {
    if (this.selectedImage && FileReader) {
      const fr = new FileReader();
      fr.onload = () => {
        this.imageSrc = fr.result;
      };
      fr.readAsDataURL(this.selectedImage);
    }
  },
};
