<template>
  <div class="container bg-white">
    <h2 class="text-error">
      {{ $t('passportFailed.title') }}
    </h2>
    <div
      v-if="imageSrc"
      class="error-image"
      :style="{ 'background-image': 'url(' + imageSrc + ')' }"
    />
    <p class="text-description align-center">
      {{ $t('passportFailed.description') }}
    </p>
    <div class="end-content">
      <a
        class="btn btn-dark"
        href="javascript:"
        @click="$emit('next-step', { step: 'PASSPORT_UPLOAD' })"
      >
        {{ $t('passportFailed.tryAgain') }}
      </a>
    </div>
  </div>
</template>
<script>
import ErrorComponent from '../../mixins/ErrorComponent';

export default {
  name: 'PassportFailed',
  mixins: [ErrorComponent],
  emits: ['next-step'],
};
</script>
