<template>
  <div class="privacy-policy">
    <div class="container bg-dark text-white">
      <h1>
        FACEM.IO Privacy Policy
      </h1>
      <h3>
        Document history
      </h3>
      <ul>
        <li> 2021-12-06 Document created</li>
      </ul>
      <h3>
        Purpose
      </h3>
      <p>
        Our enterprise provides the technical solution used by various other companies (Data Controllers, Controllers) in order to perform remote identification of a Controller’s customers (Data Subjects). The solution consists of a web site used to collect Personal Data, and a set of algorithms that process the collected data (images) to provide to the Controller information extracted from the data. The purpose of this document is to inform the general public and Data Subjects on the details of  Personal Data collection, storage and processing by our enterprise, as well as the rights that the Data Subjects have by law.
      </p>
      <h3>Definitions</h3>
      <ul>
        <li><b>Data Subject (you)</b> – the natural person, whose data is processed.</li>
        <li><b>GDPR</b> – the legal framework that sets guidelines for the collection and processing of Personal Data from Data Subjects who live in the European Union (EU).</li>
        <li><b>Personal Data</b> – any information which is related to an identified or identifiable natural person.</li>
        <li><b>Processing</b> – any operation or set of operations performed upon personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</li>
        <li><b>Data Controller</b> – a legal person, who determines the purposes of any personal data and the means of processing it. Usually, Data Controller is an organization that we have a service agreement with and that uses our technology to perform Data Processing.</li>
        <li><b>Data Processor (us, we)</b> – a legal person, who processes personal data on behalf of a Data Controller.</li>
        <li><b>Consent</b> – any freely given, specific, informed and unambiguous indication of his or her wishes by which the Data Subject, either by a statement or by a clear affirmative action, signifies agreement to personal data relating to them being processed.</li>
      </ul>
      <h3>Processor’s contact information</h3>
      <p>SIA “FACEM”, Stabu 15-51, Riga LV-1010, Latvia, mail@facem.io</p>
      <h3>Collection, Storage and Processing of information</h3>
      <p>
        Our websites collects, stores, and processes your following information:
      </p>
      <ul>
        <li>Face photographs and/or video recordings;</li>
        <li>Identity document photographs;</li>
        <li>All and any information that may be read from your documents photographs and videos;</li>
        <li>IP addresses;</li>
        <li>Device fingerprints;</li>
      </ul>
      <p>
        The purposes of collection, storing and processing of Personal Data are:
      </p>
      <ul>
        <li>Provide services as specified in agreements concluded between us and the Data Controllers;</li>
        <li>Ensure sustainability of the services we provide, increase the accuracy of information we retrieve and provide to the Data Controller;</li>
        <li>Provide details to the law enforcement agencies for criminal investigations in case such requests arise;</li>
        <li>Improve your user experience during the process of data collection;</li>
      </ul>
      <p>
        The information is stored and processed in Frankfurt (Germany) using the services provided by  DigitalOcean LLC.
      </p>
      <p>
        The duration of the storage of Personal Data on our servers is agreed by us with the Data Controller in the service agreement.
      </p>
      <h3>
        Rights
      </h3>
      <p>
        As a Data Subject, you have rights as specified in the Chapter 3 of the GDPR. Please note that in order to claim your rights, a request should be made to the Data Controller. Acting as Data Processor, FACEM will cooperate with the Data Controller to fulfil any legitimate  request from Data Subjects. We accept requests regarding Personal Data at mail@facem.io and will respond within one month from receiving such a request.
      </p>
      <ul>
        <li>Right to access. You have the right to know what Personal Data is collected, why it has been collected and if and how it was automatically processed, but also request a copy of your Personal Data.</li>
        <li>Right to rectify. You can request to correct the Personal Data if it is incomplete or incorrect.</li>
        <li>Right to erase. You can request to delete all your Personal Data, and in case there are no legal grounds to store the Personal Data, it will be deleted based on the request of the Data Controller.</li>
        <li>Right to portability. You shall be entitled to receive Personal Data about you that you submitted in a structured, commonly used and machine-readable format, and you shall have the right to transmit or request to transfer this Personal Data to another Data Controller, where technically feasible and the Personal Data has not been deleted by that time.</li>
        <li>Right to object. If we perform automated decision-making (including profiling) that will have a significant effect on you, then you may file an objection and require human intervention in the decision-making process.</li>
        <li>Right to restrict processing. You shall have the right to obtain from the controller restriction of processing in certain cases (e.g. when the accuracy of Data Processing has been contested, when the Data Processing is unlawful, or if you have exercised your Right to object).</li>
        <li>Right to withdraw consent. At any time, you can request withdrawal of consent to process your Personal Data.</li>
        <li>File a complaint. In the case you your rights have been breached you may file a complaint to the Latvia State Data Inspection, at pasts@dvi.gov.lv or by phone at +371 67223131</li>
      </ul>
    </div>
  </div>
</template>
