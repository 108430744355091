import axios from 'axios';

const API_PREFIX = '/api/v1/';

const Verification = {
  start(id) {
    const url = `${API_PREFIX}verifications/${id}/start/`;

    return axios.put(
      url,
    );
  },

  cancel(id) {
    const url = `${API_PREFIX}verifications/${id}/cancel/`;

    return axios.put(
      url,
    );
  },

  createIdCardVerification(id, jsonData) {
    return this.createVerification('ID_CARD', id, jsonData);
  },

  createPassportVerification(id, jsonData) {
    return this.createVerification('PASSPORT', id, jsonData);
  },

  createVerification(type, id, jsonData) {
    const url = `${API_PREFIX}verifications/${id}/`;
    const formData = new FormData();
    formData.append('verification_type', type);
    Object.entries(jsonData).forEach(e => formData.append(e[0], e[1]));

    return axios.put(
      url,
      formData,
    );
  },

  fetchByCode(code) {
    const url = `${API_PREFIX}verification-codes/?code=${code}`;
    return new Promise(
      (resolve) => {
        const p = axios.get(url);
        p.then(
          (resp) => {
            if (resp.data.length) {
              resolve(resp.data[0]);
            } else {
              resolve(null);
            }
          },
        );
      },
    );
  },
};

export default {
  Verification,
};
