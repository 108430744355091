<template>
  <div class="container bg-white align-center">
    <img
      src="@/assets/img/facem_logo.png"
      alt="facem"
      style="margin: 50px auto; width: 200px"
    >
    <h1
      class="text-primary"
      style="margin-bottom: 42px; margin-top: 0"
    >
      {{ $t('desktopLink.identificationProcess') }}
    </h1>
    <h2
      class="text-primary"
      style="font-weight: lighter; margin-bottom: 60px; margin-top: 0"
    >
      {{ $t('desktopLink.needSmartphone') }}
    </h2>
    <div
      style="margin: 0 auto; text-align: left"
    >
      <div style="display: flex; margin-bottom: 32px">
        <i class="icon icon-camera-blue" /> {{ $t('desktopLink.openCamera') }}
      </div>
      <div style="display: flex">
        <i class="icon icon-scan-blue" /> {{ $t('desktopLink.scanQr') }}
      </div>
    </div>
    <div class="qr-container">
      <qrcode-vue
        :value="currentUrl"
        :size="177"
      />
    </div>
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'DesktopLink',
  components: { QrcodeVue },
  computed: {
    currentUrl() {
      return window.location.href;
    },
  },
};
</script>
