<template>
  <div
    v-show="cameraReady && !cameraShutter"
    class="overlay"
  >
    <div class="container">
      <div class="photo-section overlay-circle" />
      <div class="end-content">
        <h2 class="text-white">
          {{ $t('selfie.title') }}
        </h2>
        <p class="text-white overlay-text">
          {{ $t('selfie.description') }}
        </p>
        <a
          class="photo-button"
          href="javascript:"
          @click="onCapture()"
        />
        <a
          v-if="devices.length > 1"
          href="javascript:"
          class="camera-switcher"
          @click="switchToNextCamera()"
        />
      </div>
    </div>
  </div>
  <div
    v-show="cameraShutter"
    class="shutter"
  />
  <div class="camera-bg">
    <camera
      v-show="cameraReady"
      ref="camdesktop"
      :facing-mode="'user'"
      :device-id="deviceId"
      @error="onCameraError"
      @notsupported="onNotSupportedError"
      @cameras="onCameras"
      @camera-change="onCameraChange"
      @video-live="onStarted"
      @stopped="onStopped"
    />
  </div>
  <loading-screen v-show="!cameraReady" />
</template>
<script>
import Camera from '../Camera.vue';
import CameraComponent from '../mixins/CameraComponent';
import LoadingScreen from './LoadingScreen.vue';
import { USER_STEPS } from '../../utils/constants';

export default {
  name: 'Selfie',
  components: { Camera, LoadingScreen },
  mixins: [CameraComponent],
  emits: ['on-capture'],
  data() {
    return {
      cameraShutter: false,
    };
  },
  methods: {
    onCapture() {
      this.cameraShutter = true;
      const ref = this.cameraRef();
      if (ref) {
        this.cameraImage = ref.capture();
      }
      const data = {
        type: 'selfie',
        image: this.dataURLtoFile(this.cameraImage, 'camera.jpg'),
        shouldCheckFace: true,
        successStep: USER_STEPS.VERIFICATION_SUCCESS,
        failureStep: USER_STEPS.SELFIE_FAILED,
        isLastStep: true,
      };
      setTimeout(() => {
        this.$emit('on-capture', data);
      }, 50);
    },
  },
};
</script>
<style>

</style>
