import { createWebHistory, createRouter } from 'vue-router';
import Home from '../views/Home.vue';
import Verification from '../views/Verification.vue';
import PageNotFound from '../views/PageNotFound.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import CameraTest from '../views/CameraTest.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/verify/:code',
    name: 'verification',
    component: Verification,
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/_camtest',
    name: 'cameraTest',
    component: CameraTest,
  },
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
