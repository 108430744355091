import { createApp } from 'vue';
import * as VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import setUpAxiosCsrfConfig from './utils/csrf';

import en from './i18n/en.json';
import lv from './i18n/lv.json';
import ru from './i18n/ru.json';
import ro from './i18n/ro.json';

setUpAxiosCsrfConfig();

const messages = {
  en,
  lv,
  ru,
  ro,
};

const i18n = VueI18n.createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

const app = createApp(App);

app.use(i18n);
app.use(router);
app.mount('#app');
