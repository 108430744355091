<template>
  <video
    ref="video"
    class="face-video-elem ev-video"
    :src="source"
    :autoplay="autoplay"
    webkit-playsinline="true"
    playsinline="true"
    :class="facingMode === 'user' ? 'video-selfie' : ''"
  />
</template>

<script>

// const MIN_SCREEN_HEIGHT = 700;
// const MIN_SCREEN_WIDTH = 500;
// const IDEAL_SCREEN_HEIGHT = 720;
// const IDEAL_SCREEN_WIDTH = 1280;

import isApple from '../utils/device';

export default {
  name: 'VueWebCam',

  props: {
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: 500,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    screenshotFormat: {
      type: String,
      default: 'image/jpeg',
    },
    selectFirstDevice: {
      type: Boolean,
      default: false,
    },
    deviceId: {
      type: String,
      default: null,
    },
    playsinline: {
      type: Boolean,
      default: true,
    },
    resolution: {
      type: Object,
      default: null,
      validator: value => value.height && value.width,
    },
    facingMode: {
      type: Object,
      default: null,
    },
  },

  emits: ['cameras', 'camera-change', 'video-live', 'notsupported', 'started', 'stopped', 'error'],

  data() {
    return {
      source: null,
      canvas: null,
      camerasListEmitted: false,
      cameras: [],
      localDeviceId: this.deviceId,
    };
  },

  watch: {
    deviceId(id) {
      this.changeCamera(id);
    },
    facingMode(obj) {
      this.loadCameraConstraints(
        {
          video: {
            facingMode: obj,
            width: { min: 1024, max: 4096, ideal: 1920 },
            height: { min: 768, max: 2160, ideal: 1080 },
          },
          audio: false,
        },
      );
    },
  },

  async mounted() {
    if (isApple()) {
      await this.setupMedia();
    }
    await this.loadCameras();
    if (this.facingMode && this.localDeviceId) {
      const constraints = {
        video: {
          facingMode: this.facingMode,
          width: { min: 1024, max: 4096, ideal: 1920 },
          height: { min: 768, max: 2160, ideal: 1080 },
        },
        audio: false,
      };
      if (this.facingMode === 'environment') {
        constraints.video.deviceId = { exact: this.localDeviceId };
      }
      this.loadCameraConstraints(constraints);
    }
  },

  beforeUnmount() {
    this.stop();
  },

  methods: {
    /**
     * get user media
     */
    legacyGetUserMediaSupport() {
      return (constraints) => {
        // First get ahold of the legacy getUserMedia, if present
        const getUserMedia = navigator.getUserMedia
          || navigator.webkitGetUserMedia
          || navigator.mozGetUserMedia
          || navigator.msGetUserMedia
          || navigator.oGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(
            new Error('getUserMedia is not implemented in this browser'),
          );
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise((resolve, reject) => getUserMedia.call(navigator, constraints, resolve, reject));
      };
    },

    /**
     * setup media
     */
    async setupMedia() {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }

      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = this.legacyGetUserMediaSupport();
      }
      return this.testMediaAccess();
    },

    /**
     * load available cameras
     */
    async loadCameras() {
      return navigator.mediaDevices
        .enumerateDevices()
        .then((deviceInfos) => {
          const cameras = [];
          for (let i = 0; i !== deviceInfos.length; i += 1) {
            const deviceInfo = deviceInfos[i];
            if (deviceInfo.kind === 'videoinput') {
              cameras.push(deviceInfo);
            }
          }
          this.cameras = cameras.reverse();
        })
        .then(() => {
          if (!this.camerasListEmitted) {
            if (this.cameras.length > 0) {
              this.localDeviceId = this.cameras[0].deviceId;
            }

            this.$emit('cameras', this.cameras);
            this.camerasListEmitted = true;
          }
        })
        .catch(error => this.$emit('notsupported', error));
    },

    /**
     * change to a different camera stream, like front and back camera on phones
     */
    changeCamera(deviceId) {
      this.stop();
      this.$emit('camera-change', deviceId);
      this.loadCamera(deviceId);
    },

    /**
     * load the stream to the
     */
    loadSrcStream(stream) {
      if ('srcObject' in this.$refs.video) {
        // new browsers api
        this.$refs.video.srcObject = stream;
      } else {
        // old broswers
        this.source = window.HTMLMediaElement.srcObject(stream);
      }
      // Emit video start/live event
      this.$refs.video.onloadedmetadata = () => {
        this.$emit('video-live', stream);
      };

      this.$emit('started', stream);
    },

    /**
     * stop the selected streamed video to change camera
     */
    stopStreamedVideo(videoElem) {
      const stream = videoElem.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        // stops the video track
        track.stop();
        this.$emit('stopped', stream);

        this.$refs.video.srcObject = null;
        this.source = null;
      });
    },

    // stop the video
    stop() {
      if (this.$refs.video !== null && this.$refs.video.srcObject) {
        this.stopStreamedVideo(this.$refs.video);
      }
    },

    // start the video
    start() {
      if (this.deviceId) {
        this.loadCamera(this.deviceId);
      }
    },

    // pause the video
    pause() {
      if (this.$refs.video !== null && this.$refs.video.srcObject) {
        this.$refs.video.pause();
      }
    },

    // resume the video
    resume() {
      if (this.$refs.video !== null && this.$refs.video.srcObject) {
        this.$refs.video.play();
      }
    },

    /**
     * test access
     */
    async testMediaAccess() {
      const constraints = { video: true, audio: false };

      constraints.video = {
        width: { min: 640, max: 4096, ideal: 1920 },
        height: { min: 480, max: 2160, ideal: 1080 },
      };

      return navigator.mediaDevices
        .getUserMedia(constraints)
        .catch(error => this.$emit('error', error));
    },

    /**
     * load the camera passed as index!
     */
    loadCamera(device) {
      const constraints = { video: { deviceId: { exact: device }, width: { min: 1024, max: 4096, ideal: 1920 }, height: { min: 768, max: 2160, ideal: 1080 } }, audio: false };

      if (this.resolution) {
        constraints.video.height = this.resolution.height;
        constraints.video.width = this.resolution.width;
      }

      this.loadCameraConstraints(constraints);
    },

    loadCameraConstraints(constraints) {
      let adjustedConstraints = constraints;
      if (constraints.video.facingMode === 'user' && isApple()) {
        navigator.mediaDevices
          .getUserMedia({ video: { facingMode: 'environment' }, audio: false })
          .then(stream => this.loadSrcStream(stream))
          .catch(error => this.$emit('error', error));
        adjustedConstraints = { video: { facingMode: 'user' }, audio: false };
      }
      navigator.mediaDevices
        .getUserMedia(adjustedConstraints)
        .then(stream => this.loadSrcStream(stream))
        .catch(error => this.$emit('error', error));
    },

    /**
     * capture screenshot
     */
    capture() {
      return this.getCanvas().toDataURL(this.screenshotFormat);
    },

    /**
     * get canvas
     */
    getCanvas() {
      const { video } = this.$refs;
      if (!this.ctx) {
        const canvas = document.createElement('canvas');
        /**
         * Hack for stretchy images from ios devices
         */
        canvas.height = Math.max(video.videoHeight, video.videoWidth);
        canvas.width = Math.min(video.videoHeight, video.videoWidth);
        this.canvas = canvas;

        this.ctx = canvas.getContext('2d');
      }

      const { ctx, canvas } = this;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      return canvas;
    },
  },
};
</script>
<style>
  .face-video-elem {
    width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  .video-selfie {
    transform: scaleX(-1);
    filter: flipH();
    object-position: center;
  }
</style>
