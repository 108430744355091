import * as faceapi from 'face-api.js';

export default class Detector {
  static detect(type, img) {
    switch (type) {
      case 'face':
        return faceapi.detectAllFaces(img, new faceapi.TinyFaceDetectorOptions());
      default:
        return faceapi.detectAllFaces(img, new faceapi.TinyFaceDetectorOptions());
    }
  }
}
