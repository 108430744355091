<template>
  <div class="main-container ev-step-box">
    <header class="header">
      <div class="container is-fluid line has-text-centered pt-4 pb-4 has-h-4">
        <a
          href="/"
          style="text-align: center"
        >
        </a>
      </div>
    </header>

    <div class="box box-view-page is-shadowless">
      <main>
        <div id="buttons">
          <button @click="getSupportedConstraints()">Get supported constraints</button>
          <button @click="enumerateDevices()">Enumerate devices</button>
          <button @click="showOutput = !showOutput">Toggle output</button>
        </div>

        <div v-if="showOutput" id="output" class="wrapper">
          <textarea v-model="output"></textarea>
        </div>

        <fieldset>
          <div>
            <fieldset>
              <legend>Facing mode</legend>
              <input type="radio" id="empty-facing-mode" value="" v-model="facingMode" />
              <label for="empty-facing-mode">Empty</label>

              <input type="radio" id="environment-facing-mode" value="environment" v-model="facingMode" />
              <label for="environment-facing-mode">Environment</label>

              <input type="radio" id="user-facing-mode" value="user" v-model="facingMode" />
              <label for="user-facing-mode">User</label>
            </fieldset>
          </div>

          <div>
            <fieldset>
              <legend>Audio</legend>
              <input type="radio" id="empty-resolution" value="" v-model="audioType" />
              <label for="empty-audio">Empty</label>

              <input type="radio" id="true-audio" value="true" v-model="audioType" />
              <label for="true-audio">True</label>

              <input type="radio" id="false-audio" value="false" v-model="audioType" />
              <label for="false-audio">False</label>
            </fieldset>
          </div>
          <div>
            <fieldset>
              <legend>Resolution</legend>
              <input type="radio" id="empty-resolution" value="" v-model="resolutionType" />
              <label for="empty-resolution">Empty</label>

              <input type="radio" id="exact-resolution" value="exact" v-model="resolutionType" />
              <label for="exact-resolution">Exact</label>

              <input type="radio" id="minmax-resolution" value="minmax" v-model="resolutionType" />
              <label for="minmax-resolution">Min/Max/Ideal</label>

              <div v-if="resolutionType == 'exact'">
                <select v-model="exactResolutionName" id="resolution-name">
                  <option v-for="r in resolutions" :key="r.name">
                    {{ r.name }} width: {{ r.width }} height: {{ r.height }}
                  </option>
                </select>
              </div>

              <div v-if="resolutionType == 'minmax'">
                <div>
                  <label>Width:</label>
                  <label for="minmax-width-min">Min:</label>
                  <select v-model="minWidth" id="minmax-width-min">
                    <option v-for="w in widths" :key="w">
                      {{ w }}
                    </option>
                  </select>

                  <label for="minmax-width-max">Max:</label>
                  <select v-model="maxWidth" id="minmax-width-max">
                    <option v-for="w in widths" :key="w">
                      {{ w }}
                    </option>
                  </select>

                  <label for="minmax-width-ideal">Ideal:</label>
                  <select v-model="idealWidth" id="minmax-width-ideal">
                    <option v-for="w in widths" :key="w">
                      {{ w }}
                    </option>
                  </select>
                </div>

                <div>
                  <label>Height:</label>
                  <label for="minmax-height-min">Min:</label>
                  <select v-model="minHeight" id="minmax-height-min">
                    <option v-for="w in widths" :key="w">
                      {{ w }}
                    </option>
                  </select>

                  <label for="minmax-height-max">Max:</label>
                  <select v-model="maxHeight" id="minmax-height-max">
                    <option v-for="w in widths" :key="w">
                      {{ w }}
                    </option>
                  </select>

                  <label for="minmax-height-ideal">Ideal:</label>
                  <select v-model="idealHeight" id="minmax-height-ideal">
                    <option v-for="w in widths" :key="w">
                      {{ w }}
                    </option>
                  </select>
                </div>
              </div>
            </fieldset>
          </div>
          <div v-if="devices.length > 0">
              <label for="device">Device:</label>
              <select v-model="device" id="device">
                <option v-for="d in devices" :key="d.deviceId">
                  {{ d.deviceId }}
                </option>
              </select>
          </div>
          <div><button @click="genConstraints()">Gen constraints</button></div>
        </fieldset>

        <div class="constraints wrapper">
          Constraints:
          <textarea v-model="constraints"></textarea>
        </div>

        <div><button @click="applyConstraints()">Take camshot</button></div>

        <div id="videoblock">
          <p id="dimensions">{{ dimensions }}</p>

          <video ref="video"
            id="gum-res-local"
            webkit-playsinline="true"
            playsinline
            autoplay
            @onloadedmetadata="displayVideoDimensions('loadedmetadata')"
            @onresize="displayVideoDimensions('resize')"></video>
          <div id="width">
              <label>Width <span>{{ widthOutput }}</span>px:</label>
              <input type="range" min="0" max="7680" @onchange="constraintChange($event)" v-model="widthInput">
          </div>
          <input id="sizelock" type="checkbox" @change="sizeLockChange($event)" v-model="sizeLock">Lock video size<br>
          <input id="aspectlock" type="checkbox" v-model="aspectLock">Lock aspect ratio<br>
        </div>

      </main>
    </div>
  </div>
</template>

<script>

let stream;

let currentWidth = 0;
let currentHeight = 0;


export default {
  name: 'CameraTest',
  components: {
  },
  data() {
    return {
      output: '',
      showOutput: false,
      haveStream: false,
      widthInput: 0,
      widthOutput: '',
      sizeLock: false,
      aspectLock: false,
      devices: [],
      device: null,
      dimensions: '',
      facingMode: 'environment',
      widths: [null, 240, 320, 480, 640, 720, 1080, 1280, 1920, 2160, 3840, 4096, 4320, 7680],
      resolutions: [
        { width: 320, height: 240, name: 'qvga' },
        { width: 640, height: 480, name: 'vga' },
        { width: 1280, height: 720, name: 'hd' },
        { width: 1920, height: 1080, name: 'fullhd' },
        { width: 3840, height: 2160, name: 'tv4k' },
        { width: 4096, height: 2160, name: 'cinema4k' },
        { width: 7680, height: 4320, name: '8k' },
      ],
      exactResolutionName: 'qvga',
      constraints: '',
      audioType: '',
      resolutionType: '',
      minWidth: null,
      maxWidth: null,
      idealWidth: null,
      minHeight: null,
      maxHeight: null,
      idealHeight: null,
    };
  },
  methods: {
    enumerateDevices() {
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          this.output = JSON.stringify(devices, null, 2);
          this.devices = [{ deviceId: null }].concat(devices.filter(di => di.kind === 'videoinput'));
        });
    },

    getSupportedConstraints() {
      const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
      this.output = JSON.stringify(supportedConstraints, null, 2);
    },

    genConstraints() {
      const constraints = {
        video: {
        },
      };

      if (this.audioType !== '') {
        constraints.audio = this.audioType === 'true';
      }

      if (this.resolutionType === 'minmax') {
        if (this.minWidth || this.maxWidth || this.idealWidth) {
          const width = {};
          if (this.minWidth) {
            width.min = this.minWidth;
          }
          if (this.maxWidth) {
            width.max = this.maxWidth;
          }
          if (this.idealWidth) {
            width.ideal = this.idealWidth;
          }

          constraints.video.width = width;
        }

        if (this.minHeight || this.maxHeight || this.idealHeight) {
          const height = {};
          if (this.minHeight) {
            height.min = this.minHeight;
          }
          if (this.maxHeight) {
            height.max = this.maxHeight;
          }
          if (this.idealHeight) {
            height.ideal = this.idealHeight;
          }

          constraints.video.height = height;
        }
      } else if (this.resolutionType === 'exact') {
        const rr = this.resolutions.filter(r => r.name === this.exactResolutionName);

        if (rr.length) {
          const res = rr[0];
          constraints.video.width = res.width;
          constraints.video.height = res.height;
        }
      }

      if (this.facingMode) {
        constraints.video.facingMode = this.facingMode;
      }

      if (this.device) {
        constraints.video.deviceId = {
          exact: this.device,
        };
      }

      this.constraints = JSON.stringify(constraints, null, 2);
    },

    applyConstraints() {
      this.getMedia(JSON.parse(this.constraints));
    },

    getMedia(c) {
      const constraints = c;
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      this.haveStream = false;

      navigator.mediaDevices.getUserMedia(constraints)
        .then(this.gotStream)
        .catch((err) => {
          this.output = JSON.stringify(err, Object.getOwnPropertyNames(err), 2);
          this.showOutput = true;
        });
    },

    displayVideoDimensions(whereSeen) {
      if (this.$refs.video.videoWidth) {
        this.dimensions = `Actual video dimensions: ${this.$refs.video.videoWidth}x${this.$refs.video.videoHeight}px.`;
        if (currentWidth !== this.$refs.video.videoWidth
            || currentHeight !== this.$refs.video.videoHeight) {
          console.log(`${whereSeen}: ${this.dimensions}`);
          currentWidth = this.$refs.video.videoWidth;
          currentHeight = this.$refs.video.videoHeight;
        }
      } else {
        this.dimensions = 'Video not ready';
      }
    },

    constraintChange(e) {
      this.widthOutput = e.target.value;
      const track = window.stream.getVideoTracks()[0];
      let constraints;
      if (this.aspectLock) {
        constraints = {
          width: {
            exact: e.target.value,
          },
          aspectRatio: {
            exact: this.$refs.video.videoWidth / this.$refs.video.videoHeight,
          },
        };
      } else {
        constraints = {
          width: {
            exact: e.target.value,
          },
        };
      }

      track.applyConstraints(constraints)
        .then(() => {
          console.log('applyConstraint success');
          this.displayVideoDimensions('applyConstraints');
        })
        .catch((err) => {
          this.output = JSON.stringify(err, Object.getOwnPropertyNames(err), 2);
          this.showOutput = true;
        });
    },

    sizeLockChange() {
      if (this.sizeLock) {
        console.log('Setting fixed size');
        this.$refs.video.style.width = '100%';
      } else {
        console.log('Setting auto size');
        this.$refs.video.style.width = 'auto';
      }
    },

    gotStream(mediaStream) {
      stream = mediaStream;
      window.stream = mediaStream; // stream available to console

      this.$refs.video.srcObject = mediaStream;
      this.haveStream = true;

      const track = mediaStream.getVideoTracks()[0];
      const constraints = track.getConstraints();
      const capabilities = track.getCapabilities();

      this.output = JSON.stringify(capabilities, null, 2);

      console.log(`Result constraints: ${JSON.stringify(constraints)}`);
      if (constraints && constraints.width && constraints.width.exact) {
        this.widthInput = constraints.width.exact;
        this.widthOutput = constraints.width.exact;
      } else if (constraints && constraints.width && constraints.width.min) {
        this.widthInput = constraints.width.min;
        this.widthOutput = constraints.width.min;
      }
    },
  },
};
</script>
<style>
    * {
     -webkit-text-size-adjust: none;
      text-size-adjust: none;
    }

    fieldset {
      padding: 10px;
      margin: 10px;
    }

    label {
      /* width: 120px; */
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
    }

    button {
      margin: 0 10px 20px 0;
      min-width: 90px;
    }

    div#buttons {
      margin: 0 0 1em 0;
    }

    div#container {
      max-width: 100%;
    }

    p#dimensions {
      height: 1em;
      margin: 0 0 1.5em 0;
    }

    video {
      background: none;
      height: auto;
      width: auto;
    }

    .wrapper {
        padding: 20px;
        margin: 15px 0;
    }

    .constraints.wrapper {
      background-color: #0fddf8;
    }

    #output.wrapper {
      background-color: #0ff8fd;
    }

    textarea {
        width: 100%;
        height: 200px;
    }
</style>
