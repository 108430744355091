<template>
  <div class="container bg-dark">
    <h1 class="text-white">
      {{ $t('verificationSuccess.title') }}
    </h1>
    <div class="selfie-success" />
    <p class="text-white text-description align-center">
      {{ $t('verificationSuccess.description') }}
    </p>
    <div class="end-content">
      <p class="text-gray align-center">
        {{ successUrl ? $t('verificationSuccess.waitForRedirect') : $t('verificationSuccess.canCloseWindow') }}
      </p>
    </div>
  </div>
</template>
<script type="text/javascript">
import { FLOW_TYPES, USER_STEPS } from '../../utils/constants';

export default {
  name: 'VerificationSuccess',
  props: {
    successUrl: {
      type: String,
      default() {
        return '';
      },
    },
  },
  emits: ['next-step'],
  methods: {
    generateDocumentName() {
      return this.flow === FLOW_TYPES.ID_CARD ? 'ID card' : 'passport';
    },
    generateNextStep() {
      return this.flow === FLOW_TYPES.ID_CARD ? USER_STEPS.ID_UPLOAD : USER_STEPS.PASSPORT_UPLOAD;
    },
  },
};
</script>
